.admin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  &__wrapper {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__data {
    color: white;
  }

  &__user {
    font-family: 'Courier New', Courier, monospace;
    padding: 20px;
  }
}