.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family: "georgia-bold-italic";
  src: local("georgia-bold-italic"),
      url("./fonts/georgia-bold-italic.ttf") format("ttf"),
      url("./fonts/georgia-bold-italic.woff") format("woff"),
}

#root {
  // display: flex;
  background-color: black;
  font-family: sans-serif;
  // &-content {
  //   padding-left: 72px;
  //   width: calc(100% - 72px);
  // }
}

