.wrapper {
  height: 100%;
  margin: 0 auto;
  background-color: rgb(239, 239, 239);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  .form {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .reg-form__btn {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .btn {
    cursor: pointer;
  }
}