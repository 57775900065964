.container {
  max-width: 1280px;
  margin: 0 auto;
  color: white;

  .section {
    &__header {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        background: rgba(0, 0, 0, 0.516);
      }

      .logo {
        width: 140px;
        padding-right: 20px;

        @media (max-width: 600px) {
          width: 100px;
        }

        &__container {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 100%;

          h1 {
            font-size: 3em;
            color: white;
          }

          @media (max-width: 600px) {
            h1 {
              font-size: 1.5em;
            }
          }



          .description {
            width: 100%;
            text-align: left;
            display: flex;

            &__item {
              text-align: left;
              width: 50%;
              font-size: 1.5em;
             

              @media (max-width: 600px) {
                font-size: 1em;
              }



              .item {
                font-family: 'roboto-light', sans-serif;
                padding: 0px;
                padding-bottom: 5px;
                margin: 0;
                color: white;
                font-size: 20px;
              }
              .item-bolt {
                font-size: 27px;
                font-weight: 600;
              }
            }
          }
        }

        &__title {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    &__image {
      width: 100%;
      position: relative;
      z-index: 0;

      @media (max-width: 600px) {
        height: 300px;
      }
    }
  }


  .stat {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    flex-wrap: wrap;

    @media (max-width: 600px) {
      justify-content: center;
    }


    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      max-width: 200px;
      text-align: center;
      padding: 20px 10px;
      letter-spacing: 1.2px;

      span {
        font-size: 23px;
      }
      img {
        width: 100px;
        height: 100px;
      }
    }
  }

  .state {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 20px;
    position: relative;

    &__item {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: flex-start;

      @media (max-width: 1200px) {
        flex-direction: column;
        align-items: center;
        padding-bottom: 20px;
      }


      .line {
        position: absolute;
        width: 86%;
        border: 1px dashed white;
        top: 18%;

        @media (max-width: 1200px) {
          position: relative;
          top: auto;
          margin-top: 20px;
        }
      }

      .title {
        font-size: 20px;
        padding: 10px 0;
        color: #00adef;
      }
    }

    .snake__body {
      height: 40px;
      width: calc(100% - 340px);
      background: #00abef5b;
      display: block;
      top: 50%;
      left: 100px;
      border-radius: 20px;
      position: absolute;
    }

    .wrapper_l{
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding-top: 65px;
      margin-bottom: 15px;

      &-first-child {
        margin-bottom: 24px;
      }

      @media (max-width: 1200px) {
        padding-top: 20px;
      }

      img {
        width: 140px;
        height: 140px;
        border-radius: 50%;
        border: solid 10px #00abef5b;
        margin: 20px;
        margin-right: 60px;

        @media (max-width: 1200px) {
          margin-right: 0px;
        }

        @media (max-width: 1200px) {
          &:last-child {
            display: none;
          }

          &:first-child {
            display: none;
          }
        }
      }
    }

    .title__wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }

    .desk {
      display: flex;
      flex-wrap: wrap;

      @media (max-width: 1200px) {
        justify-content: center;
      }
    }
  }

  .before {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      background: rgba(0, 0, 0, 0.363);
    }

    img {
      width: 100%;
    }

    .dc {
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      width: 100%;

      p {
        font-size: 24px;
      }

      ul {
        padding: 0;
      }

      li {
        padding: 10px 0;
        text-decoration: none;
        list-style-type: none;
      }
    }
  }


  .propas {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    li {
      padding: 10px 0;
      max-width: 400px;
    }

    span {
      font-size: 24px;
      color: #00adef;
    }
  }

  .wrapper__form {
    padding: 40px 0 100px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      max-width: 400px;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .form {
    position: relative;
    max-width: 400px;
    height: 190px;
    border: 4px solid #00adef;
    border-radius: 30px;
    padding: 40px;

    &__body {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 20px;

      p {
        display: none;
        font-size: 12px;
        font-family: 'Courier New', Courier, monospace;
        color: #fe0000;
      }

      input {
        background: none;
        outline: none;
        border: 2px solid #00adef;
        color: white;
        width: 100%;
        height: 34px;
        padding: 0 10px;
        border-radius: 10px;
        transition: all .3s;

        &:focus {
          border: 1px solid #02fff7;
        }
      }

      .error {
        border: 2px solid #fe0000;
      }

      .error__text {
        display: block;
      }
    }

    button {
      position: absolute;
      background: #00adef;
      outline: none;
      border: none;
      color: white;
      text-transform: uppercase;
      width: 100px;
      height: 40px;
      cursor: pointer;
      bottom: -42px;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 20px;
      transition: all .3s;

      &:hover {
        background: #00eaff;
      }
    }
  }

  .form-textSs {
    color: rgb(0, 206, 0);
  }

  .hidden {
    display: none;
  }

}

.wrapper__contact {
  display: flex;
  flex-direction: column;
  text-align: center;
  letter-spacing: 1px;
  padding-bottom: 50px;
  p {
    margin: 0;
    margin-bottom: 10px;
  }
}